import React from 'react'
import { Box } from 'theme-ui'
import { Player, ControlBar, LoadingSpinner, BigPlayButton } from 'video-react'
import { StaticImage } from 'gatsby-plugin-image'

import 'video-react/dist/video-react.css'
import './VideoPlayer.css'

const playerProps = {
  preload: 'auto',
  muted: true,
  playsInline: true,
  aspectRatio: '3:4',
  autoPlay: false,
  poster: 'https://cdn.filestackcontent.com/output=f:pjpg/1Pq0kzMTeGzHQm8nebSQ',
}

const VideoPlayer = ({ loading, url, ...props }) => {
  return url
    ? <Player {...playerProps} {...props}>
      <source src={url} />
      <LoadingSpinner />
      <BigPlayButton position='center' />
      <ControlBar />
    </Player>
    : <Box variant='boxes.placeholder'>
      {loading
        ? <StaticImage alt='Booth Splash Quotes' src='../../images/booth-splash.png' layout='fullWidth' placeholder='blurred' />
        : <Player src="https://res.cloudinary.com/lineups-io/video/upload/eo_060.6,so_002/v1638211379/PROD-VIDEO/lineups/website/intro-sms-demo.mp4"/>
      }
    </Box>
}

export default VideoPlayer
